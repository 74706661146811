
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { getAnswer, getCommentList, postComment, getCommentSubList } from '@/api/answer'
import eventBus from '@/utils/event-bus'
import Editor from '@/plugins/Editor'
export default {
  name : 'AnswerDetail',
  components : {
    Editor
  },
  layout : 'base',
  scrollToTop : true,
  async asyncData( context ) {
    let answerDetail = null
    let commentData = null
    let coTotalPages = null
    const answerId = context.query.id
    try {
      const resA = await getAnswer( context.query.id )
      if ( resA.data.pic ) {
        resA.data.pics = resA.data.pic.split( ',' )
      }
      answerDetail = resA.data

      const p = {
        answerId : context.query.id,
        pageNum : 1,
        pageSize : 5
      }
      const resB = await getCommentList( p )
      coTotalPages = ( resB.total / 5 ).toString()
      if ( coTotalPages.indexOf( '.' ) > -1 ) {
        coTotalPages = parseInt( coTotalPages ) + 1
      }
      resB.rows.map( i => {
        i.showReply = false
        if ( parseInt( i.createBy ) ) {
          i.nickName = i.createBy.replace( /^(\d{3})\d{4}(\d{4})$/, '$1****$2' )
        } else {
          i.nickName = i.createBy
        }
        if ( i.childrenList.length > 0 ) {
          i.childrenList.map( k => {
            if ( parseInt( k.createBy ) ) {
              k.createBy = k.createBy.replace( /^(\d{3})\d{4}(\d{4})$/, '$1****$2' )
            }
            k.showReply = false
          } )
        }
      } )
      commentData = resB.rows
    } catch {
      // ignore error
    }
    return { answerId, answerDetail, commentData, coTotalPages }
  },
  data() {
    return {
      scrollTop : 0,
      loginOpen : false,
      registerOpen : false,
      answerId : null,
      homeIcon : require( '@/assets/images/icon_home.png' ),
      toTop : require( '@/assets/images/toTop.png' ),
      iconc : require( '@/assets/images/community/icon_2.png' ),
      typeList : [
        {
          name : '产品建议',
          value : '0',
          color : '#4E9F2F'
        },
        {
          name : '操作使用',
          value : '1',
          color : '#16537D'
        },
        {
          name : '交流讨论',
          value : '2',
          color : '#C6941A'
        },
        {
          name : 'Bug反馈',
          value : '3',
          color : '#8C8573'
        }
      ],
      answerDetail : null,
      commentData : null,
      showCom : false,
      answerValue : '',
      coPageNum : 1,
      coTotalPages : 1,
      answerValueT : '',
      commitLoading : false
    }
  },
  head() {
    return {
      title : `社区问答-${this.answerDetail.title}`
    }
  },

  computed : {},

  created() {
    this.answerId = this.$route.query.id
    if ( this.answerId ) {
      this.getDetail()
    }
  },
  mounted() {
    eventBus.$emit( 'getPageData', true )
  },

  methods : {
    getDetail() {
      getAnswer( this.answerId ).then( res => {
        console.log( 234, res )
        if ( res.data.pic ) {
          res.data.pics = res.data.pic.split( ',' )
        }
        this.answerDetail = res.data
        // this.goToTop()
      } )
    },
    getCommentList() {
      const p = {
        answerId : this.answerId,
        pageNum : this.coPageNum,
        pageSize : 5
      }
      getCommentList( p ).then( res => {
        if ( this.coPageNum <= this.coTotalPages ) {
          this.coTotalPages = ( res.total / 5 ).toString()
          if ( this.coTotalPages.indexOf( '.' ) > -1 ) {
            this.coTotalPages = parseInt( this.coTotalPages ) + 1
          }
          res.rows.map( i => {
            i.showReply = false
            if ( parseInt( i.createBy ) ) {
              i.nickName = i.createBy.replace( /^(\d{3})\d{4}(\d{4})$/, '$1****$2' )
            } else {
              i.nickName = i.createBy
            }
            i.answerValue = ''
            if ( i.childrenList.length > 0 ) {
              i.childrenList.map( k => {
                if ( parseInt( k.createBy ) ) {
                  k.createBy = k.createBy.replace( /^(\d{3})\d{4}(\d{4})$/, '$1****$2' )
                }
                k.showReply = false
                k.answerValue = ''
              } )
            }
          } )
          if ( res.rows.length > 0 ) {
            this.commentData = this.commentData.concat( res.rows )
          } else {
            this.commentData = []
          }
          this.commitLoading = false
        }
      } )
    },
    askComment() {
      if ( !this.$store.getters.token && this.$store.getters.token == undefined ) {
        eventBus.$emit( 'loginOrRegister', 'openLogin' )
      } else {
        this.showCom = true
      }
    },
    toReplyA( item ) {
      if ( !this.$store.getters.token && this.$store.getters.token == undefined ) {
        eventBus.$emit( 'loginOrRegister', 'openLogin' )
      } else {
        item.showReply = !item.showReply
      }
    },
    async answerQuestion( t, v ) {
      this.commitLoading = true
      let p
      if ( t == 'ansTop' ) {
        if ( this.answerValueT == '' || !this.answerValueT ) {
          this.$message.warning( '请填写内容' )
          this.commitLoading = false
          return false
        }
        p = {
          answerId : this.answerDetail.id,
          content : this.answerValueT
        }
        await postComment( p ).then( res => {
          if ( res.code == 200 ) {
            this.$message.success( '提交成功' )
            this.answerValueT = ''
            this.answerValue = ''
          }
        } )
      } else if ( t == 'ansItem' ) {
        if ( v.answerValue == '' || !v.answerValue ) {
          this.$message.warning( '请填写内容' )
          this.commitLoading = false
          return false
        }
        p = {
          answerId : v.answerId,
          content : v.answerValue,
          replyFromUser : this.$store.getters.name,
          replyToUser : v.createBy,
          parentId : v.id
        }
        await postComment( p ).then( res => {
          if ( res.code == 200 ) {
            this.$message.success( '提交成功' )
            v.answerValue = ''
            this.answerValueT = ''
          }
        } )
      }
      this.commentData = []
      this.coPageNum = 1
      this.coTotalPages = 1
      setTimeout( () => {
        this.getCommentList()
      }, 500 )
    },
    moreComAns() {
      this.coPageNum = this.coPageNum + 1
      this.getCommentList()
    },
    subMoreCom( v ) {
      const p = {
        answerId : v.answerId,
        parentId : v.id,
        id : v.childrenList[v.childrenList.length - 1].id,
        top : 10
      }
      getCommentSubList( p ).then( res => {
        if ( res.rows.length > 0 ) {
          res.rows.map( u => {
            u.showReply = false
            v.childrenList.push( u )
          } )
        } else {
          v.isMore = 1
        }
        this.$forceUpdate()
      } )
    },
    stowCom( v ) {
      v.childrenList = v.childrenList.splice( 0, 2 )
      v.isMore = 11
    },
    goToTop() {
      if ( process.browser ) {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
        window.scrollTo( {
          left : 0,
          top : 0,
          behavior : 'smooth'
        } )
      }
    },
    back() {
      this.$nextTick( () => {
        this.$router.push( '/community' )
      } )
    }
  }
}

