import { render, staticRenderFns } from "./communityAsk.vue?vue&type=template&id=6d1485a8&scoped=true"
import script from "./communityAsk.vue?vue&type=script&lang=js"
export * from "./communityAsk.vue?vue&type=script&lang=js"
import style0 from "./communityAsk.vue?vue&type=style&index=0&id=6d1485a8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d1485a8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoAndReRegister: require('/app/components/LoAndRe/register.vue').default,LoAndReLogin: require('/app/components/LoAndRe/login.vue').default})
