
  import {caseDetail, listCaseList} from '@/api/industry'
  import 'quill/dist/quill.core.css'
  import 'quill/dist/quill.snow.css'
  import 'quill/dist/quill.bubble.css'
  import {isMobile} from '@/utils/utils'
  import eventBus from '@/utils/event-bus'

  export default {
    name: 'IndustryCaseDetails',
    layout: 'base',
    async asyncData(context) {
      const id = context.query.id
      const industry = context.query.industry || ''
      let details = null
      let caseList = []

      try {
        if (id) {
          const resp = await caseDetail(id)
          details = resp?.data
          if (details) {
            details.content = details.content.replace(/<img/g, '<img style=\'max-width:100%;height:auto;\'')
            details.content = details.content.replace(/<iframe/g, '<iframe style=\'width:100%;height:500px;\'')
          }
        }

        const caseResp = await listCaseList({
          pageNum: 1,
          pageSize: 10,
          industry: industry
        })
        const arr = caseResp?.rows?.filter(item => item.id !== id)
        caseList = arr.splice(0, 6)
      } catch {
        // ignore error
      }
      return {id, industry, details, caseList}
    },
    data() {
      return {
        fullscreenLoading: true,
        id: null,
        industry: null,
        details: null,
        caseList: [],
        defaultImg: require('@/assets/images/default.png'),
        backImg: require('@/assets/images/btn_last.png'),
        homeIcon: require('@/assets/images/icon_home.png')
      }
    },
    head() {
      return {
        title: '行业案例 - ' + (this.details?.title || '')
      }
    },
    watch: {
      async $route() {
        this.$nuxt.refresh()
      }
    },
    mounted() {
      eventBus.$emit('activeIndex', '7')
      eventBus.$emit('getPageData', true)
      const ua = isMobile()
      if (process.browser) {
        if (ua) {
          window.location.href = `/w/industryCase/detail?id=${this.$route.query.id}`
        } else {
          this.fullscreenLoading = false
        }
      }
    },
    methods: {
      shareUrl() {
        const inputNode = document.createElement('input') // 创建input
        inputNode.value = `${window.location.origin}/industryCase/details?id=${this.details.id}` // 赋值给 input 值
        document.body.appendChild(inputNode) // 插入进去
        inputNode.select() // 选择对象
        document.execCommand('Copy') // 原生调用执行浏览器复制命令
        inputNode.className = 'oInput'
        inputNode.style.display = 'none' // 隐藏
        this.$message.success('复制成功')
      }
    }
  }

